
import { defineComponent, onMounted, ref, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { StepperComponent } from "@/assets/ts/components/_StepperComponent";
import { useForm, Field, Form } from "vee-validate";
import { object, string, number, array } from "yup";
import membersApi from "@/core/services/MembersApi";
import institutionApi from "@/core/services/InstitutionApi";
import geoApi from "@/core/services/GeoService";
import Places from "./crafted/widgets/Places.vue";
import phoneCodes from "@/core/data/phonecodes";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import axios from "axios";

function getBase64(file) {
    return new Promise((resolve, reject) => {
        if (file === undefined) {
            return resolve(null);
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        }
    });
}

export default defineComponent({
    name: "AddMember",
    components: {
        Form,
        Field,
        Places,
    },
    data() {
        return {
            formKey: 0,
            placesKey: 0,
            displayCompetitor: false,
            displayInstitutionForm: false,
            sameMembers: [] as any,
            sameMembersFields: [] as any,
            remoteMembers: [] as any,
            remoteInstitutions: [] as any,
            spokenLanguages: [] as any,
            newAddress: false,
            createMemberInstitution: "",
            selectedGrade: "",
            spouseMember: "",
            sponsor1: "",
            sponsor2: "",
            institutionName: "",
            institutionForm: false,
            institutionSelected: false,
            displayOmgdDue: false,
            loadingSimilar: false,

            memberChildren: [] as any,
            remoteChild: "",

            newMemberMobileCountryCode: "",
            newMemberCountryCode: "",
            remoteInstitutionName: "",
            newMemberInstitutionMobileCountryCode: "",
            newMemberInstitutionCountryCode: "",

            disableCDRDate: false,
            disableOMGDDate: false,
            disableCDRYear: false,
            disableOMGDYear: false,
            disableCDRPriceCat: false,
            disableOMGDPriceCat: false,

            // Select options
            activities: [],
            titles: [],
            grades: [],
            filtered_grades: [],
            functions: [],
            nBailliages: [] as any,
            pBailliages: [] as any,
            rBailliages: [] as any,
            childrenBailliagesLoaded: false,
            nationalities: [],
            statuses: [],
            types: [],
            commanderLvls: [],
            medals: [],
            sexes: [
                {
                    label: "Male",
                    value: "M",
                },
                {
                    label: "Female",
                    value: "F",
                },
            ],
            medalsOptions: [],
            commanderLevelsOptions: [],
            countries_codes: [] as any,
            countries: [] as any,
            states: [] as any,
            cities: [] as any,
            stars: [
                {
                    label: "1",
                    value: "1",
                },
                {
                    label: "2",
                    value: "2",
                },
                {
                    label: "3",
                    value: "3",
                },
                {
                    label: "4",
                    value: "4",
                },
                {
                    label: "5",
                    value: "5",
                },
            ],
            food_styles: [] as any,
            credit_cards: [] as any,
            dueTypes: [],
            cdrPriceCategories: [] as any,
            omgdPriceCategories: [] as any,
            memberTypes: [
                {
                    label: "Member",
                    id: 0,
                },
                {
                    label: "Professional member",
                    id: 1,
                },
            ],

            hasChildren: "No",
            childrenMemberChaine: "No",
            membership: "Amateur",
            newMember: {
                is_young_clerk: false,
                is_young_sommelier: false,
                is_pro: false,
                languages: [],
                userprofile: {},
                address: {},
                contact: {},
            } as any,
            newDues: [
                {
                    perimeter: "CDR",
                    relevant_year: "",
                    price_category: "",
                    date: "",
                },
                {
                    perimeter: "OMGD",
                    relevant_year: "",
                    price_category: "",
                    date: "",
                },
            ] as any,

            memberType: 0 as any,
            chaineCotisAmount: "",
            omgdCotisAmount: "",
            addressIsLocked: true,
            proAddressIsLocked: true,
            checkAddress: false,
            phoneCodes: phoneCodes as any,
            perm_pos: "" as any,
            lockBailliage: false,
            prospect: {} as any,
            title: "" as any,
            firstnameError: "" as any,
            nameError: "" as any,
            birthdateError: "" as any,
            phoneError: "" as any,
            emailError: "" as any,
            birthdateLastDay: new Date(
                new Date().getFullYear() - 18,
                new Date().getMonth(),
                new Date().getDate()
            ),
        };
    },
    mounted() {
        this.perm_pos = localStorage.getItem("perm_pos") as any;
        this.getFields();
        this.setFieldsByRole();
        var phoneCodes = Object.values(this.phoneCodes).sort((a: any, b: any) =>
            parseInt(a) > parseInt(b) ? 1 : -1
        );
        const array = phoneCodes.map((c) => c);
        const filteredPhoneCodes = phoneCodes.filter(
            (index: any) => !array.includes(index + 1)
        );
        this.phoneCodes = filteredPhoneCodes;
        this.title = document.querySelector(
            "#title-breadcrumbs h1"
        ) as HTMLElement;
        if (this.title) this.title.style.cssText = "display: none!important;";
    },
    beforeUnmount() {
        localStorage.removeItem("prospect");
    },
    setup() {
        const _stepperObj = ref<StepperComponent | null>(null);
        const addMemberRef = ref<HTMLElement | null>(null);
        const verifyMemberModalRef = ref<null | HTMLElement>(null) as any;
        const currentStepIndex = ref(0);
        const router = useRouter();

        function createDebounce() {
            let timeout: any;
            return function (fnc, delayMs) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    fnc();
                }, delayMs || 500);
            };
        }

        onMounted(() => {
            _stepperObj.value = StepperComponent.createInsance(
                addMemberRef.value as HTMLElement
            );
            handleStep;
            currentStepIndex.value = 0;
            (_stepperObj.value as any).goFirst();
            setCurrentPageBreadcrumbs("New member", [
                {
                    link: "/members/list/",
                    label: "Members list",
                },
            ]);
        });

        const validationSchemaStep1 = object().shape({
            title: string().required().label("Title").nullable(),
            firstname: string().required().label("Firstname"),
            lastname: string().required().label("Lastname"),
            sex: string().required().label("Gender"),
            birthdate: string()
                .required()
                .label("Birthdate is a required field")
                .nullable(),
            nationality: string().required().label("Nationality"),
            // maritalStatus: string().label("Marital status"),
            spouseMember: string().label("Spouse member"),
            otherAssociations: string().label("Other associations"),
            // function: string().label("Function").nullable(),

            // Those 3 are defined dynamically later

            // nBailliage: string()
            //     .required()
            //     .label("National Bailliage")
            //     .nullable(),
            // pBailliage: string().label("Provincial Bailliage"),
            // rBailliage: string().required().label("Regional Bailliage"),

            languages: array().label("Languages"),
            memberMobile: string()
                .matches(
                    /^(\+\d{1,3}\s?)?(\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4})?$/,
                    "Invalid phone number"
                )
                .nullable(),
            memberEmail: string().email().required().label("Member email"),
        });

        const validationSchemaStep2 = object().shape({
            grade: string().required().label("Grade").nullable(),
            sponsor1: string().required().label("Sponsor 1"),
            sponsor2: string().required().label("Sponsor 2"),
            competition_year: string().label("Competition year"),
        });

        const validationSchemaStep3 = object().shape({
            dueChaineYear: string()
                .required()
                .label("Relevant year")
                .nullable(),
            dueChaineCategory: string()
                .required()
                .label("Due category")
                .nullable(),
            dueChaineDate: string().required().label("Due date").nullable(),
        });
        const validationSchemaStep3Omgd = object().shape({
            dueChaineYear: string()
                .required()
                .label("Relevant year")
                .nullable(),
            dueChaineCategory: string()
                .required()
                .label("Due category")
                .nullable(),
            dueChaineDate: string().required().label("Due date").nullable(),
            dueOmgdYear: string().required().label("Relevant year").nullable(),
            dueOmgdCategory: string()
                .required()
                .label("Due category")
                .nullable(),
            dueOmgdDate: string().required().label("Due date").nullable(),
        });

        const validationSchemaStep4 = object().shape({
            institutionType: string()
                .required()
                .label("Institution type")
                .nullable(),
            memberType: string().required().label("Member type").nullable(),
            website: string().nullable(),
            institutionRestaurantNb: number()
                .typeError("Invalid number")
                .nullable(),
            institutionSeatsNb: number().typeError("Invalid number").nullable(),
            institutionRoomsNb: number().typeError("Invalid number").nullable(),
            institutionBenefits: string()
                .required("This field is required")
                .nullable(),
        });

        const currentSchema = computed(() => {
            return validationSchemaStep1[currentStepIndex.value];
        });

        const { handleSubmit } = useForm({
            validationSchema: currentSchema,
        });

        const totalSteps = computed(() => {
            if (!_stepperObj.value) {
                return;
            }

            return _stepperObj.value.totatStepsNumber;
        });

        const handleStep = handleSubmit((values) => {
            if (!_stepperObj.value) return;
            currentStepIndex.value++;
            _stepperObj.value.goNext();
        });

        const previousStep = () => {
            if (!_stepperObj.value) return;
            currentStepIndex.value--;
            _stepperObj.value.goPrev();
        };

        const iterateAndNullify = (obj) => {
            Object.keys(obj).forEach((key) => {
                if (obj[key] === "") {
                    obj[key] = null;
                }
            });
        };

        const closeModal = () => {
            hideModal(verifyMemberModalRef.value);
        };

        return {
            addMemberRef,
            previousStep,
            handleStep,
            totalSteps,
            currentStepIndex,
            validationSchemaStep1,
            validationSchemaStep2,
            validationSchemaStep3,
            validationSchemaStep3Omgd,
            validationSchemaStep4,
            iterateAndNullify,
            router,
            closeModal,
            debounce: createDebounce(),
        };
    },
    methods: {
        initStep1() {
            this.validationSchemaStep1 = object().shape({
                title: string().required().label("Title").nullable(),
                firstname: string().required().label("Firstname"),
                lastname: string().required().label("Lastname"),
                sex: string().required().label("Gender"),
                birthdate: string()
                    .required()
                    .label("Birthdate is a required field")
                    .nullable(),
                nationality: string().required().label("Nationality"),
                spouseMember: string().label("Spouse member"),
                otherAssociations: string().label("Other associations"),
                languages: array().label("Languages"),
                memberMobile: string()
                    .matches(
                        /^(\+\d{1,3}\s?)?(\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4})?$/,
                        "Invalid phone number"
                    )
                    .nullable(),
                memberEmail: string().email().required().label("Member email"),
            }) as any;
        },
        disabledDate(date) {
            const today = new Date();
            const eighteenYearsAgo = new Date();

            eighteenYearsAgo.setFullYear(today.getFullYear() - 18);
            return date > eighteenYearsAgo;
        },
        testSchema(values) {
            this["validationSchemaStep" + (this.currentStepIndex + 1)]
                .validate(values)
                .catch((res: any) => {
                    ElMessage({
                        type: "error",
                        message: res,
                    });
                    return 0;
                });
        },
        setFieldsByRole() {
            const currentDate = new Date();
            const currentMonth = currentDate.getMonth();

            this.newDues[0].relevant_year =
                currentMonth < 7
                    ? currentDate.getFullYear().toString()
                    : (currentDate.getFullYear() + 1).toString();
            this.newDues[0].date = new Date().toLocaleDateString("fr-FR");
            if (this.perm_pos === "2")
                this.disableCDRDate =
                    this.disableOMGDDate =
                    this.disableCDRYear =
                    this.disableOMGDYear =
                    this.disableCDRPriceCat =
                    this.disableOMGDPriceCat =
                        true;
        },
        checkYoungGrade() {
            if (this.newMember.international_competitor === "Yes") {
                if (this.selectedGrade === "Sommelier")
                    this.newMember.is_young_sommelier = true;
                if (this.selectedGrade === "Rôtisseur")
                    this.newMember.is_young_clerk = true;
                if (this.displayCompetitor && this.newMember.is_pro)
                    this.buildNewDues(true);
            } else {
                this.newMember.is_young_sommelier = false;
                this.newMember.is_young_clerk = false;
                this.buildNewDues(false);
                this.displayOmgdDue = false;
            }
        },
        setPlaceInfo(value: any, scope: string) {
            if (scope === "perso") {
                this.newMember.address = {
                    country: value.country,
                    country_code: value.iso2,
                    state: value.state,
                    postal_code: value.postalCode,
                    address: value.streetNumber + " " + value.street,
                    city: value.city,
                };
            } else if (scope === "pro") {
                this.newMember.institution.address = {
                    country: value.country,
                    country_code: value.iso2,
                    state: value.state,
                    postal_code: value.postalCode,
                    address: value.streetNumber + " " + value.street,
                    city: value.city,
                };
            }
        },
        updateInstitution() {
            const payload = {
                ...this.newMember.institution,
                institution_type:
                    this.newMember.institution.institution_type.id,
            };

            institutionApi
                .updateInstitution(this.newMember.institution.id, payload)
                .then((res) => {
                    if (res.success) {
                        ElMessage({
                            message: "Institution updated successfully",
                            type: "success",
                        });
                    }
                });
        },
        formatDate(date: string, locale?: string) {
            if (date !== null && date !== undefined) {
                var message: string;

                if (locale)
                    message = new Date(date.split(" ")[0])
                        .toLocaleString(locale)
                        .split(" ")[0];
                else
                    message = new Date(date.split(" ")[0])
                        .toLocaleString()
                        .split(" ")[0];

                return message;
            } else {
                return "";
            }
        },
        isUnder35YearsOld(birthdate: string) {
            const birthdateParts = birthdate.split("/");
            const day = parseInt(birthdateParts[0], 10);
            const month = parseInt(birthdateParts[1], 10) - 1;
            const year = parseInt(birthdateParts[2], 10);

            const birthDate = new Date(year, month, day) as any;
            const currentDate = new Date() as any;

            const ageDifference = currentDate - birthDate;
            const ageInYears = ageDifference / (365.25 * 24 * 60 * 60 * 1000);

            return ageInYears < 35;
        },
        filterGrades(e: any) {
            let check = false;
            if (e === "Pro") {
                check = true;
                this.newMember.institution = {
                    address: {},
                    contact: {},
                } as any;
                this.newMember.is_pro = true;
            } else {
                this.displayCompetitor =
                    this.newMember.is_pro =
                    this.newMember.is_young_sommelier =
                    this.newMember.is_young_clerk =
                        false;
                delete this.newMember.institution;
            }

            this.filtered_grades = this.grades.filter(
                (grade: any) => grade.is_pro === check
            );
            this.newMember.grade = "";
            this.fetchPriceCats();
        },
        checkInternationalCompetitor(e: any) {
            this.selectedGrade = e;
            if (
                (e === "Sommelier" || e === "Rôtisseur") &&
                this.isUnder35YearsOld(this.newMember.birthdate)
            ) {
                this.displayCompetitor = true;
            } else {
                this.displayCompetitor = false;
            }
        },
        goToMember(id: number) {
            const routeData = this.router.resolve({
                name: "member-details",
                params: { member_id: id },
            });
            window.open(routeData.href, "_blank");
        },
        validateStep(e: any, f?: any) {
            if (this.currentStepIndex === 0 && f) {
                // document.getElementById("submitStep" + e)?.click();
                this.validateNewMember();
            } else {
                document.getElementById("submitStep" + e)?.click();
                if (this.currentStepIndex === 1) {
                    this.fetchPriceCats();
                }
            }
        },
        validateNewMember() {
            if (this.newMember.contact.email) {
                this.loadingSimilar = true;
                const payload = {
                    userprofile: {
                        ...this.newMember.userprofile,
                    },
                    birthdate: this.newMember.birthdate,
                    contact: {
                        ...this.newMember.contact,
                    },
                };

                membersApi.validateMember(payload).then((res) => {
                    if (res.success) {
                        if (res.data.members.length && res.data.fields.length) {
                            document.getElementById("verifyMember")?.click();
                            this.sameMembers = res.data.members;
                            this.sameMembersFields = res.data.fields;
                            setTimeout(() => {
                                this.sameMembersFields.forEach((field: any) => {
                                    if (
                                        field === "Firstname/Lastname/Birthdate"
                                    ) {
                                        this.firstnameError = " ";
                                        this.nameError = " ";
                                        this.birthdateError = " ";
                                    } else if (field === "Email") {
                                        this.emailError = " ";
                                    } else if (field === "Phone") {
                                        this.phoneError = " ";
                                    }
                                });
                            }, 250);
                        } else {
                            document.getElementById("submitStep1")?.click();
                        }
                    } else {
                        document.getElementById("submitStep1")?.click();
                    }
                    this.loadingSimilar = false;
                });
                // document.getElementById("submitStep1")?.click();
            } else {
                document.getElementById("submitStep1")?.click();
            }
        },
        fetchPriceCats() {
            let payload = {
                p: 1,
                presult: 100,
                current_selection: {
                    perimeter: "CDR",
                    is_pro: this.newMember.is_pro,
                    is_young_clerk: this.newMember.is_young_clerk,
                    is_young_sommelier: this.newMember.is_young_sommelier,
                    is_young: this.isUnder35YearsOld(this.newMember.birthdate),
                    is_spouse: this.spouseMember ? true : false,
                },
            };

            membersApi.getFilteredPriceCategories(payload).then((res) => {
                this.cdrPriceCategories = res.data.object_list;
                // this.cdrPriceCategories.sort((a: any, b: any) => (a.amount > b.amount ? 1 : -1));
                this.newDues[0].price_category = this.cdrPriceCategories[0].id;
                this.setDueAmount(this.newDues[0].price_category, "chaine");
                if (this.perm_pos === "2") this.disableCDRPriceCat = true;

                payload.current_selection.perimeter = "OMGD";

                membersApi.getFilteredPriceCategories(payload).then((res2) => {
                    this.omgdPriceCategories = res2.data.object_list;
                    // this.omgdPriceCategories.sort((a: any, b: any) => (a.amount > b.amount ? 1 : -1));
                    if (this.perm_pos === "2") this.disableOMGDPriceCat = true;
                    const currentDate = new Date();
                    const currentMonth = currentDate.getMonth();
                    if (this.displayOmgdDue) {
                        if (this.newDues.length === 1)
                            this.newDues.push({
                                type: "",
                                relevant_year: "",
                                price_category: "",
                                date: "",
                                perimeter: "OMGD",
                            });
                        this.newDues[1].price_category =
                            this.omgdPriceCategories[0].id;
                        this.newDues[1].date = new Date().toLocaleDateString(
                            "fr-FR"
                        );
                        this.newDues[1].relevant_year =
                            currentMonth < 7
                                ? currentDate.getFullYear().toString()
                                : (currentDate.getFullYear() + 1).toString();
                        this.setDueAmount(
                            this.newDues[1].price_category,
                            "OMGD"
                        );
                    }
                });
            });
        },
        initInstitutionForm() {
            this.institutionSelected = false;
            this.institutionForm = true;
            this.displayInstitutionForm = false;
            this.newMember.institution = { address: {}, contact: {} };
        },
        convertToUppercase(e) {
            setTimeout(() => {
                if (e === "lastname")
                    this.newMember.userprofile[e] =
                        this.newMember.userprofile[e].toUpperCase();
                else
                    this.newMember.userprofile[e] = this.newMember.userprofile[
                        e
                    ]
                        .toLowerCase()
                        .split(" ")
                        .map((part) => {
                            if (part.includes("-")) {
                                return part
                                    .split("-")
                                    .map(
                                        (subPart) =>
                                            subPart.charAt(0).toUpperCase() +
                                            subPart.slice(1)
                                    )
                                    .join("-");
                            } else {
                                return (
                                    part.charAt(0).toUpperCase() + part.slice(1)
                                );
                            }
                        })
                        .join(" ");
            }, 50);
        },
        checkSponsorValue(sponsor: string) {
            this.remoteMembers = [];
            if (sponsor === "sponsor1") {
                this.sponsor1 = "";
            } else if (sponsor === "sponsor2") {
                this.sponsor2 = "";
            } else {
                this.spouseMember = "";
            }
        },
        handleClear(scope: string) {
            switch (scope) {
                case "perso":
                    this.newMember.address = {
                        country: "",
                        state: "",
                        city: "",
                        postal_code: "",
                        address: "",
                    };
                    break;
                case "pro":
                    this.newMember.institution.address = {
                        country: "",
                        state: "",
                        city: "",
                        postal_code: "",
                        address: "",
                    };
                    break;
                case "newAddress":
                    if (this.newAddress) {
                        this.newMember.institution.address = {
                            country: "",
                            state: "",
                            city: "",
                            postal_code: "",
                            address: "",
                        };
                        this.institutionName = "";
                        var institStreet = document.getElementById(
                            "autocomplete_memberInstitutionStreet"
                        ) as any;
                        institStreet.value = "";
                    }
                    break;
            }
        },
        getChildrenBailliages(national_bailliage_id: number) {
            this.newMember.userprofile.provincial_bailliage = null;
            this.newMember.userprofile.regional_bailliage = null;
            this.initStep1();
            if (national_bailliage_id) {
                membersApi
                    .getChildrenBailliages(national_bailliage_id)
                    .then((res) => {
                        var bailliages = res.data;
                        this.pBailliages = [];
                        this.rBailliages = [];
                        bailliages.map((bailliage: any) => {
                            if (bailliage.type === "Provincial")
                                this.pBailliages.push(bailliage);
                            if (bailliage.type === "Regional")
                                this.rBailliages.push(bailliage);
                        });

                        // Check bailliages
                        let bailliageSchema;

                        if (this.rBailliages.length > 0) {
                            bailliageSchema = object().shape({
                                nBailliage: string()
                                    .label("National Bailliage")
                                    .nullable()
                                    .required(),
                                pBailliage: string()
                                    .label("Provincial Bailliage")
                                    .nullable(),
                                rBailliage: string()
                                    .label("Regional Bailliage")
                                    .nullable(),

                                // 643e aller-retour client...
                                // .required(),
                            });
                        } else {
                            bailliageSchema = object().shape({
                                nBailliage: string()
                                    .label("National Bailliage")
                                    .nullable(),
                                pBailliage: string()
                                    .label("Provincial Bailliage")
                                    .nullable(),
                                rBailliage: string()
                                    .label("Regional Bailliage")
                                    .nullable(),
                            });
                        }

                        this.validationSchemaStep1 =
                            this.validationSchemaStep1.concat(
                                bailliageSchema
                            ) as any;

                        this.formKey++;
                        this.childrenBailliagesLoaded = true;
                    });
            }
        },
        buildNewDues(e: any) {
            this.fetchPriceCats();
            if (e) {
                this.displayOmgdDue = true;
                this.newMember.is_omgd = true;
            } else {
                this.displayOmgdDue = false;
                this.newMember.is_omgd = false;
            }
        },
        clearInstitutionAddress() {
            if (this.newAddress) {
                this.institutionName = "";
                this.newMember.institution.address.address = "";
                this.newMember.institution.address.country = "";
                this.newMember.institution.address.state = "";
                this.newMember.institution.address.city = "";
                this.newMember.institution.address.postal_code = "";
            }
        },
        getCountryStates(countryCode: string) {
            if (countryCode !== "") {
                geoApi.getStatesByCountry(countryCode).then((response) => {
                    this.states = [];
                    response.map((item) => {
                        this.states.push({
                            id: item.id,
                            label: item.name,
                            iso2: item.iso2,
                        });
                    });
                    this.states.sort((a: any, b: any) =>
                        a.label > b.label ? 1 : -1
                    );
                });
            }
        },
        getCountryCities(countryCode: string) {
            if (countryCode !== "") {
                geoApi.getCitiesByCountry(countryCode).then((response) => {
                    this.cities = [];
                    response.map((item) => {
                        this.cities.push({
                            id: item.id,
                            label: item.name,
                        });
                    });
                });
            }
        },
        loadRemoteMembers(query, scope?: string) {
            return new Promise((resolve) => {
                if (query !== "" && query.length >= 2) {
                    this.remoteMembers = [];
                    var payload = { query: query } as any;
                    if (scope && scope !== "") payload.type = scope;
                    membersApi.listRemote(payload).then((response) => {
                        if (response.success) {
                            response.data.map((remoteMember) => {
                                this.remoteMembers.push({
                                    id: remoteMember.id,
                                    firstname:
                                        remoteMember.userprofile.firstname,
                                    lastname: remoteMember.userprofile.lastname,
                                    code: remoteMember.code,
                                    grade: remoteMember.grade
                                        ? remoteMember.grade
                                        : "-",
                                    national_bailliage: remoteMember.userprofile
                                        .national_bailliage
                                        ? remoteMember.userprofile
                                              .national_bailliage.label
                                        : "-",
                                    provincial_bailliage: remoteMember
                                        .userprofile.provincial_bailliage
                                        ? remoteMember.userprofile
                                              .provincial_bailliage.label
                                        : "-",
                                    regional_bailliage: remoteMember.userprofile
                                        .regional_bailliage
                                        ? remoteMember.userprofile
                                              .regional_bailliage.label
                                        : "-",
                                    is_pro: remoteMember.is_pro,
                                    is_young_sommelier:
                                        remoteMember.is_young_sommelier,
                                    is_cdr_uptodate:
                                        remoteMember.is_cdr_uptodate,
                                    is_active: remoteMember.is_active,
                                    due_date: remoteMember.due_date,
                                    due_type: remoteMember.due_type,
                                });
                            });

                            if (this.newMember.sponsor1 !== null)
                                this.remoteMembers = this.remoteMembers.filter(
                                    (item: any) =>
                                        item.id !== this.newMember.sponsor1
                                );

                            if (this.newMember.sponsor2 !== null)
                                this.remoteMembers = this.remoteMembers.filter(
                                    (item: any) =>
                                        item.id !== this.newMember.sponsor2
                                );

                            resolve("");
                        } else {
                            console.log("ERROR");
                        }
                    });
                } else {
                    this.remoteMembers = [];
                    resolve("");
                }
            });
        },
        loadRemoteInstitutions(query) {
            return new Promise((resolve) => {
                if (query !== "" && query.length >= 2) {
                    this.remoteInstitutions = [];
                    institutionApi
                        .listRemote({ query: query })
                        .then((response) => {
                            if (response.success) {
                                response.data.map((remoteInstitution) => {
                                    this.remoteInstitutions.push({
                                        name: remoteInstitution.name,
                                        institution_type:
                                            remoteInstitution.institution_type,
                                        speciality:
                                            remoteInstitution.speciality,
                                        stars: remoteInstitution.stars,
                                        restaurant_amount:
                                            remoteInstitution.restaurant_amount,
                                        chaine_plaque_displayed:
                                            remoteInstitution.chaine_plaque_displayed,
                                        seats_amount:
                                            remoteInstitution.seats_amount,
                                        // rooms_amount: remoteInstitution.rooms_amount,
                                        benefits: remoteInstitution.benefits,
                                        food_styles:
                                            remoteInstitution.food_styles,
                                        credit_cards:
                                            remoteInstitution.credit_cards,
                                        address: {
                                            country:
                                                remoteInstitution.address
                                                    .country,
                                            state: remoteInstitution.address
                                                .state,
                                            city: remoteInstitution.address
                                                .city,
                                            street: remoteInstitution.address
                                                .address,
                                            zip: remoteInstitution.address
                                                .postal_code,
                                        },
                                        date_updated:
                                            remoteInstitution.date_updated,
                                        id: remoteInstitution.id,
                                        picture: remoteInstitution.picture,
                                    });
                                });
                                resolve("");
                            } else {
                                console.log("ERROR");
                            }
                        });
                } else {
                    this.remoteMembers = [];
                    resolve("");
                }
            });
        },
        querySearchAsync(queryString, cb) {
            this.loadRemoteMembers(queryString).then(() => {
                cb(this.remoteMembers);
            });
        },
        querySearchAsyncSpouse(queryString, cb) {
            this.loadRemoteMembers(queryString, "spouse").then(() => {
                cb(this.remoteMembers);
            });
        },
        querySearchAsyncSponsor(queryString, cb) {
            this.loadRemoteMembers(queryString, "sponsor").then(() => {
                cb(this.remoteMembers);
            });
        },
        querySearchAsyncInstitution(queryString, cb) {
            this.loadRemoteInstitutions(queryString).then(() => {
                cb(this.remoteInstitutions);
            });
        },
        handleSelect(item: any, input: any) {
            if (input === "spouse_member") {
                this.spouseMember = item.firstname + " " + item.lastname;
                this.newMember.spouse_member = item.id;
            } else if (input === "sponsor1") {
                this.sponsor1 = item.firstname + " " + item.lastname;
                this.newMember.sponsor1 = item.id;
            } else if (input === "sponsor2") {
                this.sponsor2 = item.firstname + " " + item.lastname;
                this.newMember.sponsor2 = item.id;
            } else if (input === "institution") {
                this.displayInstitutionForm = false;
                this.newMember.institution.name = item.name;
                this.institutionName = item.name;
                this.newMember.institution.id = item.id;
                this.newMember.institution.address.state = item.address.state
                    ? item.address.state
                    : "";
                this.newMember.institution.address.country = item.address
                    .country
                    ? item.address.country
                    : "";
                this.newMember.institution.address.city = item.address.city
                    ? item.address.city
                    : "";
                this.newMember.institution.address.postal_code = item.address
                    .zip
                    ? item.address.zip
                    : "";
                this.newMember.institution.date_updated = item.date_updated
                    ? item.date_updated
                    : "";
                this.newMember.institution.institution_type = item
                    .institution_type.id
                    ? item.institution_type.id
                    : "";
                this.newMember.institution.speciality = item.speciality
                    ? item.speciality
                    : "";
                this.newMember.institution.stars = item.stars ? item.stars : 0;
                this.newMember.institution.restaurant_amount =
                    item.restaurant_amount ? item.restaurant_amount : 0;
                this.newMember.institution.chaine_plaque_displayed =
                    item.chaine_plaque_displayed ? true : false;
                this.newMember.institution.seats_amount = item.seats_amount
                    ? item.seats_amount
                    : 0;
                // this.newMember.institution.rooms_amount = item.rooms_amount ? item.rooms_amount : "";
                this.newMember.institution.benefits = item.benefits
                    ? item.benefits
                    : "";
                this.newMember.institution.picture = item.picture
                    ? item.picture
                    : "";
                this.newMember.institution.food_styles = item.food_styles
                    ? item.food_styles
                    : "";
                this.newMember.institution.credit_cards = item.credit_cards
                    ? item.credit_cards
                    : "";
                this.newMember.institution.food_styles = item.food_styles.map(
                    (item: any) => {
                        return item.id;
                    }
                );
                this.newMember.institution.credit_cards = item.credit_cards.map(
                    (item: any) => {
                        return item.id;
                    }
                );
                this.institutionSelected = true;
                this.institutionForm = false;
            } else if (input === "child") {
                this.memberChildren.push(item);
            }
            this.remoteMembers = [];
            this.remoteInstitutions = [];
        },
        setDueAmount(e: any, perimeter: string) {
            if (perimeter === "chaine") {
                this.cdrPriceCategories.map((item: any) => {
                    if (item.id === e) this.chaineCotisAmount = item.amount;
                });
            } else {
                this.omgdPriceCategories.map((item: any) => {
                    if (item.id === e) this.omgdCotisAmount = item.amount;
                });
            }
        },
        handleImage(e) {
            const selectedImage = e.target.files[0];
            if (selectedImage) {
                getBase64(selectedImage).then((base64Document: any) => {
                    this.newMember.institution.picture = base64Document;
                });
                const reader = new FileReader();
                reader.onload = (ev) => {
                    if (ev.target) {
                        this.newMember.institution.picture = ev.target
                            .result as string;
                    }
                };
                reader.readAsDataURL(selectedImage);
            }
        },
        removeImage() {
            const input = document.getElementById("institPic") as any;
            if (input) input.value = "";

            this.newMember.institution.picture = "";
        },
        getFields() {
            membersApi.getNationalities({ p: 1, presult: 9999 }).then((res) => {
                this.nationalities = res.data.object_list;
            });
            membersApi.getFunctions({ p: 1, presult: 9999 }).then((res) => {
                this.functions = res.data.object_list;
            });
            membersApi.getSpokenLanguages().then((res: any) => {
                this.spokenLanguages = res.data;
            });
            membersApi.getBailliages().then((res) => {
                var bailliages = res.data;
                bailliages.map((bailliage: any) => {
                    if (bailliage.type === "National")
                        this.nBailliages.push(bailliage);
                    // if (bailliage.type === "Provincial")
                    //     this.pBailliages.push(bailliage);
                    // if (bailliage.type === "Regional")
                    //     this.rBailliages.push(bailliage);
                });
                if (this.perm_pos >= 2) {
                    this.newMember.userprofile.national_bailliage = parseInt(
                        localStorage.getItem("bailliage") as any
                    );
                    this.getChildrenBailliages(
                        parseInt(this.newMember.userprofile.national_bailliage)
                    );
                    this.lockBailliage = true;
                }

                // Check bailliages
                let bailliageSchema;

                if (this.rBailliages.length > 0) {
                    bailliageSchema = object().shape({
                        nBailliage: string()
                            .label("National Bailliage")
                            .nullable()
                            .required(),
                        pBailliage: string()
                            .label("Provincial Bailliage")
                            .nullable(),
                        rBailliage: string()
                            .label("Regional Bailliage")
                            .nullable(),

                        // 643e aller-retour client...
                        // .required(),
                    });
                } else {
                    bailliageSchema = object().shape({
                        nBailliage: string()
                            .label("National Bailliage")
                            .nullable()
                            .required(),
                        pBailliage: string()
                            .label("Provincial Bailliage")
                            .nullable(),
                        rBailliage: string()
                            .label("Regional Bailliage")
                            .nullable(),
                    });
                }

                this.validationSchemaStep1 = this.validationSchemaStep1.concat(
                    bailliageSchema
                ) as any;

                this.formKey++;

                // Check for prospect
                if (localStorage.getItem("prospect")) {
                    this.prospect = JSON.parse(
                        localStorage.getItem("prospect") as any
                    );

                    this.newMember = {
                        userprofile: {
                            firstname: this.prospect.firstname,
                            lastname: this.prospect.lastname,
                            national_bailliage:
                                this.prospect.national_bailliage.id,
                        } as any,
                        sponsor1: this.prospect.sponsor.id,
                        birthdate: this.prospect.birthdate,

                        contact: {
                            phone: this.prospect.phone_number,
                            email: this.prospect.email,
                        },
                        address: {},
                        contact_pro: {},
                        institution: {
                            address: {},
                        },
                    };

                    this.sponsor1 =
                        this.prospect.sponsor.userprofile.firstname +
                        " " +
                        this.prospect.sponsor.userprofile.lastname;
                }
            });
            membersApi
                .getMaritalStatuses({ p: 1, presult: 9999 })
                .then((res) => {
                    this.statuses = res.data.object_list;
                });
            membersApi.getGrades({ p: 1, presult: 9999 }).then((res) => {
                this.grades = res.data.object_list;
                this.filtered_grades = this.grades.filter(
                    (grade: any) => grade.is_pro === false
                );
            });
            membersApi.getMedals({ p: 1, presult: 9999 }).then((res) => {
                this.medalsOptions = res.data.object_list;
            });
            membersApi.getCommanders({ p: 1, presult: 9999 }).then((res) => {
                this.commanderLevelsOptions = res.data.object_list;
            });
            membersApi
                .getActivitySectors({ p: 1, presult: 9999 })
                .then((res) => {
                    this.activities = res.data.object_list;
                });
            membersApi.getTitles({ p: 1, presult: 9999 }).then((res) => {
                this.titles = res.data.object_list;
            });
            membersApi
                .getInstitutionTypes({ p: 1, presult: 9999 })
                .then((res) => {
                    this.types = res.data.object_list;
                });
            membersApi.getDuesTypes({ p: 1, presult: 9999 }).then((res) => {
                this.dueTypes = res.data.object_list;
                // this.dueTypes.map((type: any) => {
                //     if (type.code === "ADM") {
                //         this.newDues[0].type = type.id;
                //     }
                // });
            });
            institutionApi
                .getFoodStyles({ p: 1, presult: 9999 })
                .then((res: any) => {
                    this.food_styles = res.data.object_list;
                });
            institutionApi
                .getCreditCards({ p: 1, presult: 9999 })
                .then((res: any) => {
                    this.credit_cards = res.data.object_list;
                });
            geoApi.getCountries().then((res) => {
                res.map((country: any) => {
                    this.countries.push({
                        id: country.id,
                        label: country.name,
                        iso2: country.iso2,
                    });

                    this.countries_codes.push({
                        id: country.id,
                        label: country.iso2,
                    });
                });
            });
            this.checkAddress = true;
        },
        formSubmit() {
            if (this.createMemberInstitution === "" && this.memberType === 1) {
                Swal.fire({
                    title: "An error occurred",
                    text: "Please specify if you want to send the specification form.",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Ok",
                    customClass: {
                        confirmButton: "btn fw-bold btn-light-danger",
                    },
                });
            } else {
                this.newMember.childrens = this.memberChildren.map(
                    (child: any) => {
                        return child.id;
                    }
                );
                this.createMemberInstitution === "Yes"
                    ? (this.newMember.is_admin_creating_institution = true)
                    : (this.newMember.is_admin_creating_institution = false);
                this.newMember.is_omgd
                    ? (this.newMember.is_omgd = true)
                    : (this.newMember.is_omgd = false);
                !this.newMember.is_omgd && this.newDues[1]
                    ? (this.newDues = this.newDues.filter(
                          (coti: any) => coti.perimeter !== "OMGD"
                      ))
                    : null;
                this.newMember.cotisation = this.newDues;
                this.addMember(this.newMember);
            }
        },
        addMember(member: any) {
            console.log(member);
            membersApi
                .addMember(member)
                .then((res: any) => {
                    console.log("RES", res);

                    if (res.data.status === 409) {
                        Swal.fire({
                            title: "Error",
                            text: "A member with this email already exists.",
                            icon: "error",
                            showCancelButton: false,
                            confirmButtonText: "Dismiss",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-danger",
                            },
                        });
                    } else if (res.data.success) {
                        if (
                            res.data.data.institution &&
                            member.is_admin_creating_institution === true
                        ) {
                            const fileInput = document.getElementById(
                                "institPic"
                            ) as any;
                            const file = fileInput?.files[0];
                            const fileInstance = new File([file], file?.name);
                            const formData = new FormData();

                            if (fileInstance) {
                                formData.append("file", fileInstance);
                                axios
                                    .post(
                                        "/api/v1/institution/" +
                                            res.data.data.institution +
                                            "/upload-image/",
                                        formData,
                                        {
                                            headers: {
                                                "Content-Type":
                                                    "multipart/form-data",
                                            },
                                        }
                                    )
                                    .then((response: any) => {
                                        console.log(response);
                                    })
                                    .catch((error: any) => {
                                        console.log(error);
                                    });
                            }
                        }
                        ElMessage({
                            type: "success",
                            message: "Member successfully added.",
                        });
                        this.router.push({ name: "members-list" });
                    } else {
                        ElMessage({
                            type: "error",
                            message:
                                "An error occurred. Something might be wrong with the fields registered. Please contact to the administrator with the member's information (screenshots/plain text). It will be easier to help you.",
                        });
                    }
                })
                .catch((err) => {
                    Swal.fire({
                        title: "Error",
                        text: "An error occurred. Please try again later.",
                        icon: "error",
                        showCancelButton: false,
                        confirmButtonText: "Dismiss",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-danger",
                        },
                    });
                });
        },
    },
});
